<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Manager Form</h2>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div id="input" class="p-5">
            <div class="preview">
              <div>
                <label for="regular-form-2" class="form-label">First Name && Last Name && Short Name</label>
                <div class="sm:grid grid-cols-3 gap-2">
                  <div>
                    <div>
                      <input v-model="formData.first_name"
                        id="regular-form-1"
                        type="text"
                        class="form-control"
                        placeholder="First Name"
                      />
                    </div>
                    <span class="text-primary-3" v-if="pagedata.errors.first_name">{{ pagedata.errors.first_name[0] }}</span>
                  </div>
                  <div>
                    <div>
                      <input v-model="formData.last_name"
                      id="regular-form-1"
                      type="text"
                      class="form-control"
                      placeholder="Last Name"
                      />
                    </div>
                    <span class="text-primary-3" v-if="pagedata.errors.last_name">{{ pagedata.errors.last_name[0] }}</span>
                  </div>
                  <div>
                    <div>
                      <input v-model="formData.short_name"
                      id="regular-form-1"
                      type="text"
                      class="form-control"
                      placeholder="Short Name"
                      />
                    </div>
                    <span class="text-primary-3" v-if="pagedata.errors.short_name">{{ pagedata.errors.short_name[0] }}</span>
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <label class="form-label">Email && User Role</label>
                <div class="sm:grid grid-cols-2 gap-2">
                  <div>
                    <div class="input-group">
                      <input v-model="formData.email"
                      id="regular-form-1"
                      type="email"
                      class="form-control"
                      placeholder="Email"
                      />
                    </div>
                    <span class="text-primary-3" v-if="pagedata.errors.email">{{ pagedata.errors.email[0] }}</span>
                  </div>
                  <div>
                    <select
                      class="form-select"
                      v-model="formData.user_role"
                      aria-label="Default select example"
                    >
                      <option value="2">Regular Manager</option>
                      <option value="8">Rough To Mkb Trans Manager</option>
                      <!-- <option value="1">Admin</option> -->
                    </select>
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <label for="regular-form-2" class="form-label">Mobile No & Birth Date</label>
                <div class="sm:grid grid-cols-2 gap-2">
                  <div>
                    <div>
                    <div class="relative">
                      <div
                        class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 border text-gray-600 dark:bg-dark-1 dark:border-dark-4"
                      >
                        <CalendarIcon class="w-4 h-4" />
                      </div>
                      <Litepicker
                        v-model="formData.bdate"
                        :options="{
                          autoApply: true,
                          showWeekNumbers: true,
                          dropdowns: {
                            minYear: 1950,
                            maxYear: 2004,
                            months: true,
                            years: true
                          },
                          // startDate:(new Date()).setFullYear((new Date()) - 18),
                          // setup: (picker) => {

                          //   picker.on('show', () => {
                          //     let date = picker.getDate();
                          //     if (date) {
                          //       date.setYear(date.getMonth() - 18);
                          //       // date.setMonth(1);
                          //       // date.setDate(1);
                          //       picker.gotoDate(date);
                          //     }
                          //   });

                          //   }
                        }"
                        class="form-control pl-12"
                      />
                    </div>
                    <span class="text-primary-3" v-if="pagedata.errors.bdate">{{ pagedata.errors.bdate[0] }}</span>
                  </div>
                  </div>
                  <div>
                    <div>
                      <input
                        id="regular-form-2"
                        type="tel" v-model="formData.mobile_no"
                        class="form-control form-control"
                        placeholder="987-6543-210"
                      />
                    </div>
                    <span class="text-primary-3" v-if="pagedata.errors.mobile_no">{{ pagedata.errors.mobile_no[0] }}</span>
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <label for="regular-form-4" class="form-label">Current Address</label>
                <textarea
                  id="regular-form-4"
                  type="password"
                  class="form-control"
                  v-model="formData.addr_current"
                  placeholder="Current Address"
                ></textarea>
              </div>
              <div class="mt-3">
                <label for="regular-form-5" class="form-label">Perminant Address</label>
                <textarea
                  id="regular-form-5"
                  type="text"
                  v-model="formData.addr_permant"
                  class="form-control"
                  placeholder="Permant Address"
                ></textarea>
              </div>
              <div class="mt-3">
              <label class="form-label">City & State</label>
              <div class="sm:grid grid-cols-2 gap-2">
                <div class="input-group">
                  <div id="input-group-3" class="input-group-text">City</div>
                  <input
                    type="text"
                    class="form-control"
                    v-model="formData.city"
                    placeholder="City"
                    aria-describedby="input-group-3"
                  />
                </div>
                <div class="input-group mt-2 sm:mt-0">
                  <div id="input-group-4" class="input-group-text">State</div>
                  <input
                    type="text"
                    class="form-control"
                    v-model="formData.state"
                    placeholder="State"
                    aria-describedby="input-group-4"
                  />
                </div>
              </div>
              </div>
              <div class="mt-3">
                <label class="form-label">Pincode & Material Status</label>
                <div class="sm:grid grid-cols-2 gap-2">
                  <div class="input-group">
                  <div id="input-group-3" class="input-group-text">Pincode</div>
                  <input
                    type="tel"
                    class="form-control"
                    placeholder="PinCode"
                    v-model="formData.pin"
                    aria-describedby="input-group-3"
                  />
                </div>
                  <div>
                    <select
                      class="form-select"
                      v-model="formData.material"
                      aria-label="Default select example"
                    >
                      <option value="merried">Merried</option>
                      <option value="unmerried">Unmerried</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Input -->
      </div>
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: Vertical Form -->
        <div class="intro-y box">
          <div id="vertical-form" class="p-5">
              <div class="mt-3">
                <label class="form-label">Referal Name & Mobile No</label>
                <div class="sm:grid grid-cols-2 gap-2">
                  <div class="input-group">
                    <div id="input-group-3" class="input-group-text">Name</div>
                    <input
                      type="text"
                      v-model="formData.r_name"
                      class="form-control"
                      placeholder="Name"
                      aria-describedby="input-group-3"
                    />
                  </div>
                  <div class="input-group mt-2 sm:mt-0">
                    <div id="input-group-4" class="input-group-text">Mobile</div>
                    <input
                      type="tel"
                      class="form-control"
                      v-model="formData.r_mobile"
                      placeholder="987-6543-210"
                      aria-describedby="input-group-4"
                    />
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <label class="form-label">Joining Date & End Date</label>
                <div class="sm:grid grid-cols-2 gap-2">
                  <div class="input-group">
                    <div id="input-group-3" class="input-group-text">Joining</div>
                    <Litepicker
                        v-model="formData.s_date"
                        :options="{
                          autoApply: true,
                          showWeekNumbers: true,
                          dropdowns: {
                            minYear: 2010,
                            maxYear: 2024,
                            months: true,
                            years: true,
                          },
                        }"
                        class="form-control block mx-auto"
                      />
                  </div>
                  <div class="input-group mt-2 sm:mt-0">
                    <div id="input-group-3" class="input-group-text">End</div>
                    <Litepicker
                        v-model="formData.e_date"
                        :options="{
                          autoApply: true,
                          showWeekNumbers: true,
                          dropdowns: {
                            minYear: 2010,
                            maxYear: 2030,
                            months: true,
                            years: true,
                          },
                        }"
                        class="form-control block mx-auto"
                      />
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <div>
                  <label class="form-label">Select Department</label>
                  <TomSelect
                      v-model="formData.department"
                      :options="{
                        placeholder: 'Select your favorite actors'
                      }"
                      class="w-full"
                    >
                    <option value="">Select Department</option>
                      <option v-for="dept in pagedata.departments" :key="dept.id" :value="dept.id">{{ dept.department_name }}</option>
                    </TomSelect>
                </div>
                <span class="text-primary-3" v-if="pagedata.errors.department">{{ pagedata.errors.department[0] }}</span>
              </div>
              <div class="mt-3">
                <div>
                  <label class="form-label">Select Processes</label>
                  <TomSelect
                  v-model="formData.process"
                      :options="{
                        placeholder: 'Select your favorite actors'
                      }"
                      class="w-full"
                    multiple
                    >
                      <option v-for="process in pagedata.processes" :key="process.id" :value="process.id">{{ process.process_name }}</option>
                    </TomSelect>
                </div>
                <span class="text-primary-3" v-if="pagedata.errors.process">{{ pagedata.errors.process[0] }}</span>
              </div>
              <div class="mt-3">
                <div class="grid grid-cols-12">
                  <div class="col-span-4">
                    <label class="form-label">Process Per Price</label>
                    <div class="input-group">
                      <input
                      type="checkbox"
                      v-model="formData.processper"
                      class="form-check-switch my-2"
                      />
                    </div>
                  </div>
                  <div class="col-span-4">
                    <label class="form-label">Salary Fixed</label>
                    <div class="input-group">
                      <input
                        type="checkbox"
                        v-model="formData.fixed"
                        class="form-check-switch my-2"
                      />
                    </div>

                  </div>
                  <div class="col-span-4">
                    <div>

                      <label class="form-label">Outward</label>
                      <div class="input-group">
                        <input
                          type="checkbox"
                          v-model="formData.outward"
                          class="form-check-switch my-2"
                        />
                      </div>
                      <span class="text-primary-3" v-if="pagedata.errors.outward">{{ pagedata.errors.outward[0] }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <div class="grid grid-cols-12 gap-6 mt-5">
                  <div class="col-span-12 sm:col-span-6" v-if="formData.process_limit.length > 0">

                    <div>
                      <form class="w-full">
                        <thead>
                          <tr class="border-b bg-gray-200 dark:bg-dark-1">
                            <!-- <th class="py-2 border-b text-xs lg:text-md text-center border-r"> # </th> -->
                            <td class="py-2 border-b text-center border-r">Process</td>
                            <td class="py-2 border-b text-center border-r">Limit</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item,index) in formData.process_limit" :key="index">
                            <!-- <th class="p-2 border-b text-xs lg:text-md text-center border-r">{{ index + 1 }}</th> -->
                            <td class="p-2 border-b text-center border-r">{{ item.process_name }}</td>
                            <td class="p-2 border-b text-center border-r"><input type="number" class="form-control text-center w-24" v-model="item.limit"></td>
                          </tr>
                        </tbody>
                      </form>
                    </div>
                  </div>
                  <div class="col-span-12 sm:col-span-6">
                    <div>
                      <div>
                        <div class="input-group sm:mt-0">
                          <div id="input-group-4" class="input-group-text whitespace-nowrap">Packet Delay</div>
                          <input
                            type="text"
                            class="form-control"
                            v-model="formData.packet_delay"
                            placeholder="Assign Delay Limit"
                            aria-describedby="input-group-3"
                          />
                        </div>
                        <div class="text-primary-3" v-if="pagedata.errors.packet_delay">{{ pagedata.errors.packet_delay[0] }}</div>

                      </div>
                      <div class=" mt-2">

                        <div class="input-group" v-if="formData.fixed">
                          <div id="input-group-4" class="input-group-text">Salary</div>
                          <input
                            type="text"
                            class="form-control"
                            v-model="formData.salary"
                            placeholder="Salary"
                            aria-describedby="input-group-3"
                          />
                        </div>
                        <div class="text-primary-3" v-if="pagedata.errors.salary">{{ pagedata.errors.salary[0] }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-right mt-5">
                <router-link :to="{ name: 'manager-details' }" class="btn btn-outline-secondary w-24 mr-1">
                  Cancel
                </router-link>
                <button type="button" @click="SaveManager" class="btn btn-primary w-24">Save</button>
              </div>
          </div>
        </div>
        <!-- END: Vertical Form -->
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import router from '@/router'
import { useRoute } from 'vue-router'
import axios from 'axios'
import { defineComponent, inject, onMounted, reactive, ref, watch } from 'vue'
import moment from 'moment'
import { stringify } from 'json5'
export default defineComponent({
  setup() {
    const pagedata = reactive({
      processes : [],
      departments : [],
      errors: [],
      error: ''
    })
    const route = useRoute()
    const formData = reactive({
      id: '',
      first_name : '',
      last_name : '',
      short_name : '',
      email : '',
      mobile_no : '',
      r_name : '',
      r_mobile : '',
      bdate : '',
      addr_current: '',
      addr_permant: '',
      city: '',
      state: '',
      pin: '',
      material: 'unmerried',
      department: '',
      s_date: '',
      e_date: '',
      process: [],
      process_limit: [],
      fixed: false,
      outward: false,
      processper: true,
      salary: '',
      packet_delay: '',
      user_role: '2',
      user_type: 1,
    })
    const swal = inject('$swal')
    function subtractYears(date, years) {
        // 👇 make copy with "Date" constructor
        const dateCopy = new Date(date);

        dateCopy.setFullYear(date.getFullYear() - years);
        dateCopy.setDate(1);
        dateCopy.setMonth(1);

        return dateCopy;
      }
    onMounted(() => {
      const date = new Date();

      formData.bdate = moment(subtractYears(date, 18)).format('DD MMM, YYYY');
      getDepartments()
      getProcesses()
      if(route.params.id) {
        formData.id = route.params.id
        getManagerDetails()
      }
    })
    function getDepartments() {
      axios({
        url: 'api/master/dp_department',
        method: 'post',
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.departments = res.data.items
      })
    }
    function getProcesses() {
      axios({
        url: 'api/master/dp_process',
        method: 'post',
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.processes = res.data.items
      })
    }
    function getProcessLimitDetails() {
      axios({
        url: 'api/master/manager_process_limit_details',
        method: 'post',
        baseURL: BASE_URL,
        data: { id: route.params.id},
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        formData.process_limit = res.data.items
      })
    }
    function SaveManager() {
      formData.bdate = moment(formData.bdate).format('YYYY-MM-DD');
      formData.s_date = moment(formData.s_date).format('YYYY-MM-DD');
      formData.e_date = moment(formData.e_date).format('YYYY-MM-DD');
      if(route.params.id) {
        axios({
          url: 'api/master/update_user_details',
          method: 'post',
          baseURL: BASE_URL,
          data: formData,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then(() => {
          router.push({ name: 'manager-details' })
        }).catch((err) => {
          if(err.response.data.error) {
            pagedata.error = err.response.data.error
            swal("Warning!", pagedata.error, "warning");
          } else {
            pagedata.errors = err.response.data.errors
            setTimeout(() => {
              pagedata.errors = []
            }, 3000);
          }
        })
      } else {
          axios({
          url: 'api/master/add_new_user',
          method: 'post',
          baseURL: BASE_URL,
          data: formData,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then(() => {
          router.push({ name: 'manager-details' })
        }).catch((err) => {
          if(err.response.data.error) {
            pagedata.error = err.response.data.error
            swal("Warning!", pagedata.error, "warning");
          } else {
            pagedata.errors = err.response.data.errors
            setTimeout(() => {
              pagedata.errors = []
            }, 3000);
          }
        })
      }
    }
    function getManagerDetails() {
      const params = {
        id : route.params.id, user_type: formData.user_type
      }
      axios({
          url: 'api/master/show_user_details',
          method: 'post',
          baseURL: BASE_URL,
          data: params,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          formData.first_name = res.data.item.first_name
          formData.last_name = res.data.item.last_name
          formData.short_name = res.data.item.short_name
          formData.email = res.data.item.email
          formData.mobile_no = res.data.item.mobile_no
          formData.r_name = res.data.item.ref_name
          formData.r_mobile = res.data.item.ref_mobile
          formData.bdate = res.data.item.dob
          formData.addr_current = res.data.item.current_addr
          formData.addr_permant = res.data.item.permenamt_addr
          formData.city = res.data.item.city
          formData.state = res.data.item.state
          formData.pin = res.data.item.pincode
          formData.material = res.data.item.merital_status == 0 ? 'unmerried':'merried'
          formData.department = stringify(res.data.item.department)
          formData.s_date = res.data.item.joining_date ?  res.data.item.joining_date : ''
          formData.e_date = res.data.item.resign_date ? res.data.item.resign_date : ''
          formData.fixed = res.data.item.salary_fixed ? true:false
          formData.salary = res.data.item.salary
          formData.packet_delay = res.data.item.packet_delay
          formData.outward = res.data.item.outward
          formData.user_role = res.data.item.user_role == 2 ? '2' : (res.data.item.user_role == 8 ? '8' : '1')
          formData.process = res.data.item.process == null ? []:res.data.item.process

          getProcessLimitDetails()
        })

    }
    watch(() => formData.fixed, (CurrentVal, OldVal) => {
        formData.processper = !formData.fixed
    })
    watch(() => formData.processper, (CurrentVal, OldVal) => {
        formData.fixed = !formData.processper
    })
    watch(() => formData.process, (CurrentVal, OldVal) => {
        if(CurrentVal.length > OldVal.length) {
          let process_id = formData.process[CurrentVal.length - 1]

          pagedata.processes.forEach(item => {
            if(item.id == process_id) {
              formData.process_limit.push({
                id: item.id,
                process_name: item.process_name,
                limit: null,
              })
            }
          });
        } else {
          let process_id = OldVal.filter(x => CurrentVal.indexOf(x) === -1);
          let length = formData.process_limit.length

          while(length--){
            if(formData.process_limit[length]['id'] == process_id[0] ){
                formData.process_limit.splice(length,1);
            }
          }
        }
    })
    watch
    return {
      formData,
      pagedata,
      SaveManager,
      // addProcessForLimit
    }
  }
})
</script>
