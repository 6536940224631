<template>
  <div id="basic-non-sticky-notification-packet-error" class="toastify-content hidden flex flex-col sm:flex-row">
    <div class="flex items-center justify-around">
      <CheckCircleIcon class="w-6 h-6 text-theme-6 mx-auto" />
      <div class="font-medium text-black" v-if="pagedata.error">{{ pagedata.error }}</div>
    </div>
  </div>
  <div id="basic-non-sticky-notification-packet-receive" class="toastify-content hidden flex flex-col sm:flex-row">
    <div class="flex items-center justify-around">
      <CheckCircleIcon class="w-6 h-6 text-theme-9 mx-auto" />
      <div class="font-medium text-black" v-if="pagedata.success.success">{{ pagedata.success.success }}</div>
    </div>
  </div>
  <div class="my-4">
    <div class="grid grid-cols-12 gap-x-2">
      <div class="col-span-12 lg:col-span-4">
        <div>
          <div class="flex items-center">
            <div class="text-lg font-medium mr-2">Please Scan the Packet</div>
            <div>
              <input id="remember-me" type="checkbox" v-model="pagedata.check"
                class="form-check-input border mr-2 bg-white" />
              <label for="remember-me" class="text-md font-medium">Menual</label>
            </div>
          </div>
          <div class="w-full flex sm:w-auto xl:mt-0 mt-3 sm:ml-auto md:ml-0">
            <div class="w-56 relative text-gray-700 dark:text-gray-300 mr-4">
              <input type="text" @change="getPacketDetailsScan" v-model="pagedata.search" autofocus ref="focusMe"
                class="form-control w-56 box pr-10 placeholder-theme-13" placeholder="Search..." />
              <SearchIcon class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0" />
            </div>
            <button class="btn btn-primary shadow-md mr-2" @click="getPaketDetail">Search</button>
          </div>
        </div>
      </div>
      <div class="col-span-12 lg:col-span-8">
        <div class="flex items-end mt-10 xl:mt-0" v-if="pagedata.show_details">
          <div class="mx-5">
            <h2 class="intro-y text-lg font-medium">Packet No : {{ pagedata.packet_details.number }}</h2>
            <table class="w-48">
              <tbody>
                <tr>
                  <th class="py-2 font-bold border-2 text-md px-1 text-left whitespace-nowrap" v-if="pagedata.packet_details.vepari_no"> Stock</th>
                  <th class="py-2 font-bold border-2 text-md px-1 text-left whitespace-nowrap" v-if="pagedata.packet_details.stock_type">Lot Type</th>
                  <th class="py-2 font-bold border-2 text-md px-1 text-left whitespace-nowrap" v-if="pagedata.packet_details.size_name"> Size</th>
                  <th class="py-2 font-bold border-2 text-md px-1 text-left whitespace-nowrap" v-if="pagedata.packet_details.shape">Shape </th>
                  <th class="py-2 font-bold border-2 text-md px-1 text-left whitespace-nowrap" v-if="pagedata.packet_details.color">Color </th>
                  <th class="py-2 font-bold border-2 text-md px-1 text-left whitespace-nowrap" v-if="pagedata.packet_details.purity"> Purity</th>
                  <th class="py-2 font-bold border-2 text-md px-1 text-left whitespace-nowrap" v-if="pagedata.packet_details.cut">Cut</th>
                  <th class="py-2 font-bold border-2 text-md px-1 text-left whitespace-nowrap" v-if="pagedata.packet_details.polish"> Polish</th>
                  <th class="py-2 font-bold border-2 text-md px-1 text-left whitespace-nowrap" v-if="pagedata.packet_details.symmetry"> Symmetry</th> <th class="py-2 font-bold border-2 text-md px-1 text-left" v-if="pagedata.packet_details.tension">
                    Tension</th>
                  <th class="py-2 font-bold border-2 text-md px-1 text-left" v-if="pagedata.packet_details.stone">Stone </th>
                  <th class="py-2 font-bold border-2 text-md px-1 text-left whitespace-nowrap" v-if="pagedata.packet_details.cr_weight">Issue Wt</th>
                  <th class="py-2 font-bold border-2 text-md px-1 text-left whitespace-nowrap" v-if="pagedata.packet_details.weight">Oriznal Wt</th>
                  <th class="py-2 font-bold border-2 text-md px-1 text-left whitespace-nowrap" v-if="pagedata.packet_details.cr_polish_weight">PWT</th>
                </tr>
                <tr>
                  <td class="font-bold text-md border-2 text-center whitespace-nowrap"
                    v-if="pagedata.packet_details.vepari_no">{{ pagedata.packet_details.vepari_no ? pagedata.packet_details.vepari_no : '' }}</td>
                  <td class="font-bold text-md border-2 text-center whitespace-nowrap" v-if="pagedata.packet_details.stock_type">{{pagedata.packet_details.stock_type == 2 ? 'Merged' : 'Single'}}</td>
                  <td class="font-bold text-md border-2 text-center whitespace-nowrap" v-if="pagedata.packet_details.size_name">{{ pagedata.packet_details.size_name ? pagedata.packet_details.size_name : '' }}</td>
                  <td class="font-bold text-md border-2 text-center whitespace-nowrap" v-if="pagedata.packet_details.shape">{{ pagedata.packet_details.shape ? pagedata.packet_details.shape : '' }}</td>
                  <td class="font-bold text-md border-2 text-center whitespace-nowrap" v-if="pagedata.packet_details.color">{{ pagedata.packet_details.color ? pagedata.packet_details.color : '' }}</td>
                  <td class="font-bold text-md border-2 text-center whitespace-nowrap" v-if="pagedata.packet_details.purity">{{ pagedata.packet_details.purity ? pagedata.packet_details.purity : '' }}</td>
                  <td class="font-bold text-md border-2 text-center whitespace-nowrap" v-if="pagedata.packet_details.cut">{{ pagedata.packet_details.cut ? pagedata.packet_details.cut : '' }}</td>
                  <td class="font-bold text-md border-2 text-center whitespace-nowrap" v-if="pagedata.packet_details.polish">{{ pagedata.packet_details.polish ? pagedata.packet_details.polish : '' }}</td>
                  <td class="font-bold text-md border-2 text-center whitespace-nowrap" v-if="pagedata.packet_details.symmetry">{{ pagedata.packet_details.symmetry ? pagedata.packet_details.symmetry : '' }}</td>
                  <td class="font-bold text-md border-2 text-center whitespace-nowrap" v-if="pagedata.packet_details.tension">{{ pagedata.packet_details.tension ? pagedata.packet_details.tension : '' }}</td>
                  <td class="font-bold text-md border-2 text-center whitespace-nowrap" v-if="pagedata.packet_details.stone">{{ pagedata.packet_details.stone ? pagedata.packet_details.stone : '' }}</td>
                  <td class="font-bold text-md border-2 text-center whitespace-nowrap" v-if="pagedata.packet_details.cr_weight">{{ pagedata.packet_details.cr_weight }}</td>
                  <td class="font-bold text-md border-2 text-center whitespace-nowrap" v-if="pagedata.packet_details.weight">{{ pagedata.packet_details.weight }}</td>
                  <td class="font-bold text-md border-2 text-center whitespace-nowrap" v-if="pagedata.packet_details.cr_polish_weight">{{ pagedata.packet_details.cr_polish_weight }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="hidden">
            <div id="paketDetails" class="mx-5">
              <table class="w-48 border-2 border-black">
                <tbody>
                  <tr>
                    <th class="p-2 font-bold text-lg text-left">
                      Packet No:
                    </th>
                    <th class="font-bold text-lg text-center">{{ pagedata.packet_details.number }}</th>
                  </tr>
                  <tr>
                    <th class="p-2 font-bold text-lg text-left">
                      Stock No:
                    </th>
                    <th class="font-bold text-lg text-center">{{ pagedata.packet_details.vepari_no }}</th>
                  </tr>
                  <tr v-if="pagedata.packet_details.shape">
                    <td class="p-2 border-2 border-black font-bold text-md text-left">
                      Shape :
                    </td>
                    <td class="font-bold text-md border-2 border-black text-center">{{ pagedata.packet_details.shape ? pagedata.packet_details.shape : '' }}</td>
                  </tr>
                  <tr v-if="pagedata.packet_details.color">
                    <td class="p-2 border-2 border-black font-bold text-md text-left">
                      Color :
                    </td>
                    <td class="font-bold text-md border-2 border-black text-center">{{ pagedata.packet_details.color ? pagedata.packet_details.color : '' }}</td>
                  </tr>
                  <tr v-if="pagedata.packet_details.purity">
                    <td class="p-2 border-2 border-black font-bold text-md text-left">
                      Purity :
                    </td>
                    <td class="font-bold text-md border-2 border-black text-center">{{ pagedata.packet_details.purity ? pagedata.packet_details.purity : '' }}</td>
                  </tr>
                  <tr v-if="pagedata.packet_details.cut">
                    <td class="p-2 border-2 border-black font-bold text-md text-left">
                      Cut :
                    </td>
                    <td class="font-bold text-md border-2 border-black text-center">{{ pagedata.packet_details.cut ? pagedata.packet_details.cut : '' }}</td>
                  </tr>
                  <tr v-if="pagedata.packet_details.polish">
                    <td class="p-2 border-2 border-black font-bold text-md text-left">
                      Polish :
                    </td>
                    <td class="font-bold text-md border-2 border-black text-center">{{ pagedata.packet_details.polish ? pagedata.packet_details.polish : '' }}</td>
                  </tr>
                  <tr v-if="pagedata.packet_details.symmetry">
                    <td class="p-2 border-2 border-black font-bold text-md text-left">
                      Symmetry :
                    </td>
                    <td class="font-bold text-md border-2 border-black text-center">{{ pagedata.packet_details.symmetry ? pagedata.packet_details.symmetry : '' }}</td>
                  </tr>
                  <tr v-if="pagedata.packet_details.tension">
                    <td class="p-2 border-2 border-black font-bold text-md text-left">
                      Tension :
                    </td>
                    <td class="font-bold text-md border-2 border-black text-center">{{ pagedata.packet_details.tension ? pagedata.packet_details.tension : '' }}</td>
                  </tr>
                  <tr v-if="pagedata.packet_details.stone">
                    <td class="p-2 border-2 border-black font-bold text-md text-left">
                      Stone :
                    </td>
                    <td class="font-bold text-md border-2 border-black text-center">{{ pagedata.packet_details.stone ? pagedata.packet_details.stone : '' }}</td>
                  </tr>
                  <tr v-if="pagedata.packet_details.cr_weight">
                    <td class="p-2 border-2 border-black font-bold text-md text-left">
                      Iss. Wt :
                    </td>
                    <td class="font-bold text-md border-2 border-black text-center">{{ pagedata.packet_details.cr_weight }}</td>
                  </tr>
                  <tr v-if="pagedata.packet_details.weight">
                    <td class="p-2 border-2 border-black font-bold text-md text-left">
                      Oriz. Wt :
                    </td>
                    <td class="font-bold text-md border-2 border-black text-center">{{ pagedata.packet_details.weight }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div>
            <div class="flex items-center">
              <button v-if="pagedata.packet_details.number != ''" type="button" v-print="'#paketDetails'"
                class="btn btn-primary w-16 mr-1 py-1">
                Print
              </button>
              <!-- <RotateCwIcon  v-if="pagedata.packet_details.number != ''" class="w-5 h-5 text-primary-6 mx-1 cursor-pointer" @click="getPaketDetail"></RotateCwIcon> -->
            </div>
            <a title="New Process Assign" data-toggle="modal" class="btn btn-primary shadow-md mt-1 py-1 w-28"
              href="javascript:;"
              v-if="pagedata.packet_details.status == 1 && pagedata.packet_details.manager_id == user_details.account_id && pagedata.packet_details.hold_transaction_id == null && pagedata.packet_details.blocked == null && pagedata.packet_details.discuss == null && pagedata.packet_details.external == null"
              @click="getProcessesCheck" data-target="#new-process-modal">New Process</a>
          </div>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-x-2 mt-8">
      <div v-if="pagedata.packet_details.status == 6"
        class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center text-primary-1">Packet is Deleivred, You can not make change to this Packet</div>
      <div v-if="pagedata.packet_details.status == 7"
        class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center text-primary-1">Packet is Merged, You can not make change to this Packet</div>
      <div v-else-if="pagedata.packet_details.status == 3"
        class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center text-primary-3">Packet is Cancelled, You can not make change to this Packet</div>
      <div v-else-if="pagedata.packet_details.external == 1"
        class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center text-primary-3">Packet in External, You can not make change to this Packet</div>
      <div v-else-if="pagedata.packet_details.discuss == 1"
        class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center text-primary-3">Packet In Discuss, You can not make change to this Packet</div>
      <div v-else-if="pagedata.packet_details.blocked == 1"
        class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center text-primary-3">Packet is Blocked, You can not make change to this Packet</div>
      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 xl:mt-0 mt-2" v-if="pagedata.show_details">
        <div class="intro-y col-span-12 overflow-auto" v-if="pagedata.transaction_details.length > 0">
          <table class="table-report -mt-2 w-full">
            <thead>
              <tr>
                <th class="py-2 whitespace-nowrap">T No</th>
                <th class="py-2 whitespace-nowrap">Proc.</th>
                <th class="py-2 whitespace-nowrap">Mgr.</th>
                <th class="py-2 text-center whitespace-nowrap">Emp.</th>
                <th class="py-2 text-center whitespace-nowrap">Issue Wt.</th>
                <th class="py-2 text-center whitespace-nowrap">Rec. Wt.</th>
                <th class="py-2 text-center whitespace-nowrap"><span>Issue Time</span><br><span>Rec. Time</span></th>
                <th class="py-2 text-center whitespace-nowrap">Shape</th>
                <th class="py-2 text-center whitespace-nowrap">Color</th>
                <th class="py-2 text-center whitespace-nowrap">Purity</th>
                <th class="py-2 text-center whitespace-nowrap">Cut</th>
                <th class="py-2 text-center whitespace-nowrap">Polish</th>
                <th class="py-2 text-center whitespace-nowrap">Symm</th>
                <th class="py-2 text-center whitespace-nowrap">Action</th>
              </tr>
            </thead>
            <tbody>
              <TableList v-for="(packet, index) in pagedata.transaction_details" :key="index" :index="index"
                :packet="packet" :packetDet="pagedata.packet_details" :diamond="diamond" @getData="getPacketDetailPkt"
                @getDiamondDetail="getDiamondDetail" @cancelProcess="cancelProcess"
                @updatParameters="updateParameters" />
            </tbody>
          </table>
        </div>
        <div v-else class="intro-y col-span-12 overflow-auto mt-2 text-center">No Packet Found</div>
      </div>
      <div v-else class="intro-y col-span-12 overflow-auto mt-2 text-center">Please Scan Packet</div>
      <!-- END: Data List -->
    </div>
  </div>
  <!-- BEGIN: Delete Confirmation Modal -->
  <div id="delete-confirmation-modal" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body p-3">
          <div class="p-5 text-center">
            <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
            <div class="text-3xl mt-5">{{ pagedata.errors.error }}</div>
          </div>
          <div class="text-gray-600 mt-2 box w-52 p-3 m-auto my-3">
            <div v-if="pagedata.errors.paket" class="text-black font-bold pb-2">Packet No : <span
                class="text-primary-3">{{ pagedata.errors.paket }}</span></div>
            <div v-if="pagedata.errors.packet_manager" class="text-black font-bold pb-2">Manager : <span
                class="text-primary-3">{{ pagedata.errors.packet_manager }}</span></div>
            <div v-if="pagedata.errors.packet_department" class="text-black font-bold m-auto pb-2">Department : <span
                class="text-primary-3">{{ pagedata.errors.packet_department }}</span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Delete Confirmation Modal -->
  <!-- BEGIN: new Process Modal -->
  <div id="new-process-modal" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <a data-dismiss="modal" href="javascript:;">
          <XIcon class="w-8 h-8 text-gray-500" />
        </a>
        <div class="modal-body p-10 text-center">
          <!-- <div id="vertical-form"> -->
          <div class="flex flex-col justify-between mt-5">
            <!-- BEGIN: Data List -->
            <div class="intro-y overflow-auto">
              <div class="flex items-center">
                <h2 class="intro-y text-lg font-medium mr-5">Packet Issue</h2>
              </div>
            </div>
            <div class="grid grid-cols-12 gap-4">
              <div class="intro-y col-span-12">
                <!-- BEGIN: Input -->
                <div class="">
                  <div id="input" class="py-5">
                    <div class="preview">
                      <div>
                        <div class="sm:grid grid-cols-2 gap-2">
                          <div>
                            <div class="">
                              <div id="input-group-4" class="text-left">Packet No</div>

                              <input id="pos-form-1" v-model="pagedata.packet_details.number" name="packet" type="text"
                                disabled class="form-control flex-1" placeholder="Packet No" />
                            </div>
                          </div>
                          <div class="mt-2 sm:mt-0">
                            <div class="">
                              <div id="input-group-4" class="text-left">Stock No</div>

                              <input id="pos-form-1" v-model="pagedata.packet_details.vepari_no" name="packet"
                                type="text" disabled class="form-control flex-1" placeholder="Stock No" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="mt-3">
                        <div class="sm:grid grid-cols-2 gap-2">
                          <div>
                            <div class="">
                              <div id="input-group-4" class="text-left">Packet Type</div>

                              <input id="pos-form-1" v-model="pagedata.packet_details.type" name="packet" type="text"
                                disabled class="form-control flex-1" placeholder="Packet Type" />
                            </div>
                          </div>
                          <div class="mt-2 sm:mt-0">
                            <div id="input-group-4" class="text-left">Issue Weight (Ct)</div>
                            <input type="text" class="form-control" name="weight" disabled
                              v-model="pagedata.packet_details.cr_weight" aria-describedby="input-group-4" />
                          </div>
                        </div>
                      </div>
                      <div class="mt-3">
                        <div id="input-group-4" class="text-left">Add Comment</div>
                        <input type="text" class="form-control" name="comment" v-model="process.comment"
                          placeholder="Add Comment" aria-describedby="input-group-4" />
                      </div>
                      <div class="mt-3">
                        <div class="text-left">Transaction Type</div>
                        <TomSelect v-model="process.transaction_type" :options="{ placeholder: 'Select Transaction Type' }" class="w-full">
                          <option value="0">Process Assign</option>
                          <option value="1">Manager Transfer</option>
                          <option value="2">Job Done</option>
                        </TomSelect>
                      </div>
                      <div class="mt-3" v-if="process.transaction_type == '1'">
                        <div class="text-left">Select Manager</div>
                        <TomSelect v-model="process.manager_id" @change="validateManagerId" :options="{ placeholder: 'Select Manager' }" class="w-full">
                          <option value="0">Select Manager</option>
                          <option v-for="(manager, ind) in pagedata.managers" :key="ind" :value="manager.id">{{ manager.first_name + ' ' + manager.last_name }}</option>
                        </TomSelect>
                        <span class="text-primary-3 mb-2" v-if="pagedata.errors.manager_id">{{ pagedata.errors.manager_id[0] }}</span>
                      </div>
                      <div class="mt-3" v-if="process.transaction_type == '0'">
                        <div class="text-left">Select Process</div>
                        <TomSelect v-model="process.process_id" @change="getEmployees()" :options="{ placeholder: 'Select Process' }" class="w-full">
                          <option value="0">Select Process</option>
                          <option v-for="(process, ind) in pagedata.processes" :key="ind" :value="process.id">{{ process.process_name }}</option>
                        </TomSelect>
                        <span class="text-primary-3 mb-2" v-if="pagedata.errors.process_id">{{ pagedata.errors.process_id[0] }}</span>

                      </div>
                      <div class="flex items-center mt-3"
                        v-if="process.process_id != '0' && process.transaction_type == '0'">
                        <div class="form-check">
                          <input
                            class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 ml-3 cursor-pointer"
                            value="0" type="radio" name="singleinternal" id="singleinternal" v-model="process.eternals">
                          <label class="form-check-label inline-block text-black text-lg" for="singleinternal">
                            Internal Employee
                          </label>
                        </div>
                        <div class="form-check ml-6">
                          <input
                            class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 ml-3 cursor-pointer"
                            value="1" type="radio" name="singleexternal" id="singleexternal" v-model="process.eternals">
                          <label class="form-check-label inline-block text-black text-lg" for="singleexternal">
                            Jon Party
                          </label>
                        </div>
                      </div>
                      <div class="mt-3"
                        v-if="process.eternals == '0' && process.process_id != '0' && process.transaction_type == '0'">
                        <div class="text-left">Select Employee</div>
                        <TomSelect v-model="process.employee_id" :options="{
        placeholder: 'Select Employee'
      }" class="w-full">
                          <option value="0">Select Employee</option>
                          <option v-for="(emp, ind) in pagedata.employees" :key="ind" :value="emp.id">{{ emp.first_name +
        ' ' + emp.last_name }}</option>
                        </TomSelect>
                        <span class="text-primary-3 mb-2" v-if="pagedata.errors.employee_id">{{
        pagedata.errors.employee_id[0] }}</span>
                      </div>
                      <div class="mt-3"
                        v-else-if="process.eternals == '1' && process.process_id != '0' && process.transaction_type == '0'">
                        <div class="text-left">Select Job Party</div>
                        <TomSelect v-model="process.party_id" :options="{
        placeholder: 'Select Party'
      }" class="w-full">
                          <option value="0">Select Party</option>
                          <option v-for="(party, ind) in pagedata.parties" :key="ind" :value="party.id">{{
        party.firm_name }}</option>
                        </TomSelect>
                        <span class="text-primary-3 mb-2" v-if="pagedata.errors.party_id">{{ pagedata.errors.party_id[0]
                          }}</span>
                      </div>
                      <div class="mt-3">
                        <!-- <label class="form-label">Stone && Est Value</label> -->
                        <div class="sm:grid grid-cols-2 gap-2">
                          <div>

                            <div class="">
                            </div>
                          </div>
                          <div>
                            <div class="mt-2 sm:mt-0">
                              <div class="flex justify-end items-center mt-5">
                                <button type="button" :disabled="pagedata.submit == true"
                                  class="btn btn-secondary w-24 m-auto mr-3" data-dismiss="modal"
                                  href="javascript:;">Cancel</button>
                                <button type="button" @click="AssignProcess" :disabled="pagedata.submit"
                                  class="btn btn-primary w-24 m-auto"> Issue
                                  <LogInIcon v-if="pagedata.submit == false" class="h-4 w-4 ml-2 -mt-1" />
                                  <LoadingIcon v-if="pagedata.submit == true" icon="oval" color="white"
                                    class="w-4 h-4 ml-2" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- END: Input -->
              </div>
            </div>
            <!-- END: Data List -->
          </div>
          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>
  <!-- END: new Process Modal -->
  <!-- BEGIN: Receive Packet Modal -->
  <div id="edit-confirmation-modal" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <a data-dismiss="modal" href="javascript:;">
          <XIcon class="w-8 h-8 text-gray-500" />
        </a>
        <div class="modal-body p-10 text-center">
          <!-- BEGIN: Vertical Form -->
          <div class="intro-y">
            <div id="vertical-form" class="p-5">
              <div class="text-lg font-medium mx-3">Packet No :- <span class="text-primary-1">{{ receiveDet.paket_no
                  }}</span></div>
              <div class="grid grid-cols-12 gap-6 mt-5">
                <div class="intro-y col-span-12 md:col-span-6" v-if="pagedata.packet_details.type == 'makeable'">
                  <div class="mt-3">
                    <div class="input-group mt-2 sm:mt-0">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Color</div>
                      <select class="form-select" v-model="receiveDet.color" aria-label="Default select example">
                        <option v-for="col in diamond.colors" :key="col.id" :value="col.id">{{ col.color_name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="mt-3">
                    <div class="input-group">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Purity</div>
                      <select class="form-select" v-model="receiveDet.purity" aria-label="Default select example">
                        <option v-for="col in diamond.purities" :key="col.id" :value="col.id">{{ col.purity_name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="mt-3">
                    <div class="input-group mt-2 sm:mt-0">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Shape</div>
                      <select class="form-select" v-model="receiveDet.shape" aria-label="Default select example">
                        <option v-for="col in diamond.shapes" :key="col.id" :value="col.id">{{ col.shape_name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="mt-3">
                    <div class="input-group mt-2 sm:mt-0">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Flurocent</div>
                      <select class="form-select" v-model="receiveDet.fluerocent" aria-label="Default select example">
                        <option v-for="col in diamond.fluerocents" :key="col.id" :value="col.id">{{ col.fluerocent_name
                          }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="mt-3">
                    <div class="input-group">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Tension</div>
                      <select class="form-select" v-model="receiveDet.tension" aria-label="Default select example">
                        <option v-for="col in diamond.tensions" :key="col.id" :value="col.id">{{ col.tension_name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="intro-y col-span-12 md:col-span-6">
                  <div class="mt-3" v-if="pagedata.packet_details.type == 'makeable'">
                    <div class="input-group">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Cut</div>
                      <select class="form-select" v-model="receiveDet.cut" aria-label="Default select example">
                        <option v-for="col in diamond.cuts" :key="col.id" :value="col.id">{{ col.cut_name }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="mt-3" v-if="pagedata.packet_details.type == 'makeable'">
                    <div class="input-group mt-2 sm:mt-0">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Polish</div>
                      <select class="form-select" v-model="receiveDet.polish" aria-label="Default select example">
                        <option v-for="col in diamond.polish" :key="col.id" :value="col.id">{{ col.polish_name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="mt-3" v-if="pagedata.packet_details.type == 'makeable'">
                    <div class="input-group">
                      <div id="input-group-3" class="input-group-text w-36 text-center">Symmerty</div>
                      <select class="form-select" v-model="receiveDet.symmetry" aria-label="Default select example">
                        <option v-for="col in diamond.symmetry" :key="col.id" :value="col.id">{{ col.symmetry_name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="mt-3">
                    <div class="mt-2 sm:mt-0">
                      <div class="input-group">
                        <input type="number" v-model="receiveDet.pktwt" class="form-control" placeholder="Weight"
                          aria-describedby="input-group-4" />
                      </div>
                      <span class="text-primary-3 text-left" v-if="pagedata.errors.pktwt">{{ pagedata.errors.pktwt[0]
                        }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <div class="form-label w-full text-left">Remark</div>
                <textarea id="regular-form-4" type="password" class="form-control" v-model="receiveDet.description"
                  placeholder="Remark"></textarea>
              </div>
              <div class="text-right mt-5">
                <button type="button" class="btn btn-primary w-24" :disabled="pagedata.submit"
                  @click="receivePKT">Receive
                  <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" />
                </button>
              </div>
            </div>
          </div>
          <!-- END: Vertical Form -->
        </div>
      </div>
    </div>
  </div>
  <!-- END: Receive Packet Modal -->
  <!-- BEGIN: Packet Receive Packet Modal -->
  <!-- END: Data List -->
  <div id="packet-process-receive-modal" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <a data-dismiss="modal" href="javascript:;">
          <XIcon class="w-8 h-8 text-gray-500" />
        </a>
        <div class="modal-body p-10">
          <!-- <div id="vertical-form"> -->
          <div class="flex flex-col justify-between mt-5">
            <!-- BEGIN: Data List -->
            <div class="intro-y overflow-auto">
              <div class="flex items-center">
                <h2 class="intro-y text-lg font-medium mr-5">Packet Receive</h2>
              </div>
            </div>
            <div class="grid grid-cols-12 gap-4">
              <div class="intro-y col-span-12">
                <!-- BEGIN: Input -->
                <div class="">
                  <div id="input" class="py-5">
                    <div class="preview">
                      <div>
                        <div class="sm:grid grid-cols-2 gap-2">
                          <div>
                            <div class="">
                              <div id="input-group-4">Packet No</div>

                              <input id="pos-form-1" v-model="receiveDet.paket_no" name="packet" type="text" disabled
                                class="form-control flex-1" placeholder="Packet No" />
                            </div>
                          </div>
                          <div class="mt-2 sm:mt-0">
                            <div class="">
                              <div id="input-group-4">Stock No</div>

                              <input id="pos-form-1" v-model="receiveDet.vepari_no" name="packet" type="text" disabled
                                class="form-control flex-1" placeholder="Stock No" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="mt-3">
                        <div class="sm:grid grid-cols-2 gap-2">
                          <div>
                            <div class="">
                              <div id="input-group-4">Packet Type</div>

                              <input id="pos-form-1" v-model="receiveDet.type" name="packet" type="text" disabled
                                class="form-control flex-1" placeholder="Packet Type" />
                            </div>
                          </div>
                          <div class="mt-2 sm:mt-0">
                            <div id="input-group-3" class="">Stone</div>
                            <select class="form-select" v-model="receiveDet.stone" disabled
                              aria-label="Default select example">
                              <option value="1">N</option>
                              <option value="2">CVD</option>
                              <option value="3">HPHT</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="mt-3">
                        <div class="sm:grid grid-cols-2 gap-2">
                          <div>
                            <div>

                              <div class="">
                                <div id="input-group-3">Process</div>
                                <input type="text" class="form-control" name="piece" disabled
                                  v-model="receiveDet.process_name" aria-describedby="input-group-3" />
                              </div>

                            </div>
                          </div>
                          <div class="mt-2 sm:mt-0">
                            <div class="">
                              <div id="input-group-4">Issue Weight (Ct)</div>
                              <input type="text" class="form-control" name="weight" disabled
                                v-model="receiveDet.issue_weight" aria-describedby="input-group-4" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="mt-3">
                        <div id="input-group-4" class="text-left">Receive Comment</div>
                        <input type="text" class="form-control" name="comment" v-model="receiveDet.comment"
                          placeholder="Receive Comment" aria-describedby="input-group-4" />
                      </div>
                      <div class="mt-3">
                        <div class="sm:grid grid-cols-2 gap-2">
                          <div>
                            <div>

                              <div class="">
                                <div id="input-group-3">Receive Weight (Ct)</div>
                                <input type="text" class="form-control" name="receive_weight"
                                  @keypress="$h.preventInvalidInput" v-model="receiveDet.receive_weight"
                                  aria-describedby="input-group-3" @change="validateReceiveWeight" />
                              </div>

                            </div>
                          </div>
                          <div class="mt-2 sm:mt-0">
                            <div class="">
                              <div id="input-group-4">Rejection Weight (Ct)</div>
                              <input type="text" class="form-control" name="assort_weight"
                                @keypress="$h.preventInvalidInput" v-model="receiveDet.assort_weight"
                                aria-describedby="input-group-4" @change="validateAssortWeight" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="mt-3">
                        <div class="sm:grid grid-cols-2 gap-2">
                          <div>
                            <div>

                              <!-- <div class="">
                                                                <div id="input-group-3">Receive Weight (Ct)</div>
                                                                <input type="text" class="form-control" name="receive_weight" @keypress="$h.preventInvalidInput" v-model="receiveDet.receive_weight" aria-describedby="input-group-3"  @change="validateReceiveWeight"/>
                                                            </div> -->

                            </div>
                          </div>
                          <div class="mt-2 sm:mt-0">
                            <div class="">
                              <div id="input-group-4">Net Rejection Weight (Ct)</div>
                              <input type="text" class="form-control" name="net_assort_weight"
                                @keypress="$h.preventInvalidInput" v-model="receiveDet.net_assort_weight"
                                aria-describedby="input-group-4" @change="validateNetAssortWeight" />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="grid grid-cols-12 gap-6 mt-3">

                        <div class="intro-y col-span-12 md:col-span-6" v-if="receiveDet.type == 'makeable'">
                          <div>
                            <div class="input-group mt-2 sm:mt-0">
                              <div id="input-group-3" class="input-group-text w-36 text-center">Color</div>
                              <select class="form-select" v-model="receiveDet.color"
                                aria-label="Default select example">
                                <option v-for="col in diamond.colors" :key="col.id" :value="col.id">{{ col.color_name }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="mt-3">
                            <div class="input-group">
                              <div id="input-group-3" class="input-group-text w-36 text-center">Purity</div>
                              <select class="form-select" v-model="receiveDet.purity"
                                aria-label="Default select example">
                                <option v-for="col in diamond.purities" :key="col.id" :value="col.id">{{ col.purity_name
                                  }}</option>
                              </select>
                            </div>
                          </div>
                          <div class="mt-3">
                            <div class="input-group mt-2 sm:mt-0">
                              <div id="input-group-3" class="input-group-text w-36 text-center">Shape</div>
                              <select class="form-select" v-model="receiveDet.shape"
                                aria-label="Default select example">
                                <option v-for="col in diamond.shapes" :key="col.id" :value="col.id">{{ col.shape_name }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <!-- <div class="mt-3">
                                                  <div class="input-group mt-2 sm:mt-0">
                                                    <div id="input-group-3" class="input-group-text w-36 text-center">Flurocent</div>
                                                    <select
                                                      class="form-select"
                                                      v-model="receiveDet.fluerocent"
                                                      aria-label="Default select example"
                                                    >
                                                      <option v-for="col in diamond.fluerocents" :key="col.id" :value="col.id">{{ col.fluerocent_name }}</option>
                                                    </select>
                                                  </div>
                                                </div>
                                                <div class="mt-3">
                                                  <div class="input-group">
                                                    <div id="input-group-3" class="input-group-text w-36 text-center">Tension</div>
                                                    <select
                                                      class="form-select"
                                                      v-model="receiveDet.tension"
                                                      aria-label="Default select example"
                                                    >
                                                      <option v-for="col in diamond.tensions" :key="col.id" :value="col.id">{{ col.tension_name }}</option>
                                                    </select>
                                                  </div>
                                                </div> -->
                        </div>
                        <div class="intro-y col-span-12 md:col-span-6"
                          v-if="pagedata.packet_details.type == 'makeable'">
                          <div>
                            <div class="input-group">
                              <div id="input-group-3" class="input-group-text w-36 text-center">Cut</div>
                              <select class="form-select" v-model="receiveDet.cut" aria-label="Default select example">
                                <option v-for="col in diamond.cuts" :key="col.id" :value="col.id">{{ col.cut_name }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="mt-3">
                            <div class="input-group mt-2 sm:mt-0">
                              <div id="input-group-3" class="input-group-text w-36 text-center">Polish</div>
                              <select class="form-select" v-model="receiveDet.polish"
                                aria-label="Default select example">
                                <option v-for="col in diamond.polish" :key="col.id" :value="col.id">{{ col.polish_name
                                  }}</option>
                              </select>
                            </div>
                          </div>
                          <div class="mt-3">
                            <div class="input-group">
                              <div id="input-group-3" class="input-group-text w-36 text-center">Symmerty</div>
                              <select class="form-select" v-model="receiveDet.symmetry"
                                aria-label="Default select example">
                                <option v-for="col in diamond.symmetry" :key="col.id" :value="col.id">{{
                                  col.symmetry_name }}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="mt-3">
                        <!-- <label class="form-label">Stone && Est Value</label> -->
                        <div class="sm:grid grid-cols-2 gap-2">
                          <div>

                            <div class="">
                            </div>
                          </div>
                          <div>
                            <div class="mt-2 sm:mt-0">
                              <div class="flex justify-end items-center mt-5">
                                <button type="button" :disabled="pagedata.submit == true"
                                  class="btn btn-secondary w-24 m-auto mr-3" data-dismiss="modal"
                                  href="javascript:;">Cancel</button>
                                <button type="button" @click="receivePKT" :disabled="pagedata.submit == true"
                                  class="btn btn-primary w-24 m-auto"> Receive
                                  <LogInIcon v-if="pagedata.submit == false" class="h-4 w-4 ml-2 -mt-1" />
                                  <LoadingIcon v-if="pagedata.submit == true" icon="oval" color="white"
                                    class="w-4 h-4 ml-2" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- END: Input -->
              </div>
            </div>
            <!-- END: Data List -->
          </div>
          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>
  <!-- BEGIN: Single Process Assign Modal -->
  <!-- END: Packet Receive Packet Modal -->
  <!-- BEGIN: Rate Not Found Process -->
  <div id="rate-show-confirmation-modal" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body p-3">
          <div class="p-5 text-center">
            <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
            <div class="text-3xl mt-5" v-if="pagedata.errors.error">{{ pagedata.errors.error }}</div>
            <div class="text-gray-600 mt-2" v-if="pagedata.errors.message">
              {{ pagedata.errors.message }}
            </div>
            <div class="text-gray-600 mt-5" v-if="pagedata.errors.data">{{ pagedata.errors.data }}</div>
          </div>
          <div class="px-5 pb-8 text-center">
            <button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">
              Cancel
            </button>
            <button type="button" class="btn btn-danger w-24" :disabled="pagedata.submit"
              v-if="pagedata.errors.error == 'Rate Not Found'" @click="createRateRequest">Send
              <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" />
            </button>
            <button type="button" class="btn btn-danger w-24" :disabled="pagedata.submit"
              v-if="pagedata.errors.error == 'Parameters are Changed'" @click="createParamRequest">Send
              <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Rate Not Found Process -->
  <!-- BEGIN: Parameter Change Packet Process -->
  <div id="middle-parameter-confirmation-modal" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body p-3">
          <div class="p-5 text-center">
            <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
            <div class="text-3xl mt-5">Are you sure?</div>
            <div class="text-gray-600 mt-2">
              Do you really want to Send Request?
            </div>
          </div>
          <div class="px-5 pb-8 text-center">
            <button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">
              Cancel
            </button>
            <button type="button" class="btn btn-danger w-24" :disabled="pagedata.submit"
              @click="sendRequestMdlParam">Send
              <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Parameter Change Packet Process -->
  <!-- BEGIN: Cancel Packet Process -->
  <div id="cancel-confirmation-modal" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body p-3">
          <div class="p-5 text-center">
            <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
            <div class="text-3xl mt-5">Are you sure?</div>
            <div class="text-gray-600 mt-2">
              Do you really want to Cancel Packet? <br />This process
              cannot be undone.
            </div>
          </div>
          <div class="px-5 pb-8 text-center">
            <button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">
              Cancel
            </button>
            <button type="button" class="btn btn-danger w-24" @click="cancelTransaction">Delete</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Cancel Packet Process -->
</template>

<script>
/* eslint-disable */
import {
  BASE_URL
} from '@/constants/config'
import axios from 'axios'
import cash from 'cash-dom'
import {
  defineComponent,
  reactive,
  toRef,
  ref,
  watch,
  onMounted,
  computed,
  inject
} from 'vue'
import print from 'vue3-print-nb'
import Toastify from 'toastify-js'
import TableList from '@/views/PacketTransaction/single-issue/tableList.vue'
import store from '@/store'
export default defineComponent({
  directives: {
    print
  },
  components: {
    TableList
  },
  setup() {
    const user_details = computed(() => store.state.user.currentUser)
    const focusMe = ref()
    const swal = inject('$swal')
    const pagedata = reactive({
      transaction_details: [],
      packet_details: [],
      search: '',
      check: false,
      waiting: false,
      employees: [],
      processes: [],
      parties: [],
      partyproc: [],
      submit: false,
      errors: [],
      managers: [],
      success: {
        success: '',
        manager: '',
      },
      show_details: false,
      error: '',
      changes: '',
    })
    const process = reactive({
      id: '',
      number: '',
      eternals: '0',
      manager_id: '0',
      process_id: '0',
      employee_id: '0',
      party_id: '0',
      transaction_type: '0',
      comment: '',
      type: ''
    })
    const diamond = reactive({
      colors: [],
      cuts: [],
      purities: [],
      symmetry: [],
      polish: [],
      fluerocents: [],
      tensions: [],
      stones: [],
      shapes: [],
    })
    const receiveDet = reactive({
      id: '',
      paket_no: '',
      vepari_no: '',
      issue_weight: '',
      receive_weight: '',
      assort_weight: '',
      net_assort_weight: '',
      process_name: '',
      lose_weight: '',
      color: '',
      cut: '',
      purity: '',
      polish: '',
      shape: '',
      symmetry: '',
      tension: '',
      stone: '',
      fluerocent: '',
      comment: '',
      pktwt: '',
      description: '',
      type: ''
    })
    onMounted(() => {
      focusMe.value.focus()
      getDiamontData()
      getAllManager()
    })

    function getPaketDetail() {
      if (pagedata.search != '' && pagedata.search != null) {
        const params = {
          number: window.btoa(pagedata.search)
        }
        axios({
          url: 'api/purchase/get_scanned_packet_details',
          method: 'post',
          baseURL: BASE_URL,
          data: params,
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
          config: {
            headers: {
              'Accept': 'application/json'
            }
          }
        }).then((res) => {
          pagedata.transaction_details = []
          pagedata.packet_details = []
          pagedata.transaction_details = res.data.packet_transaction_details
          pagedata.packet_details = res.data.paket_items
          process.id = pagedata.packet_details.id
          process.number = pagedata.packet_details.number
          pagedata.show_details = true
          setTimeout(() => {
            pagedata.search = ''
          }, 1000);
        }).catch((err) => {
          pagedata.errors = err.response.data
          cash('#delete-confirmation-modal').modal('show')
          setTimeout(() => {
            pagedata.errors = []
            cash('#delete-confirmation-modal').modal('hide')
          }, 3000);
          setTimeout(() => {
            pagedata.search = ''
          }, 1000);
          pagedata.transaction_details = []
          pagedata.packet_details = []
        })
      }
    }

    function getPacketDetailPkt() {
      pagedata.search = pagedata.packet_details.number
      // if(pagedata.check) {
      getPaketDetail()
      // }
    }

    function printData() {
      var print = this.$refs.paketDetails
      const newWin = window.open('')
      newWin.document.write(print.outerHTML)
      newWin.print()
    }

    function getProcessesCheck() {
      pagedata.processes == []
      process.process_id = '0'
      const params = {
        user_id: user_details.value.account_id,
        process_type: window.btoa(pagedata.packet_details.type)
      }
      axios({
        url: 'api/master/manager_processes',
        method: 'post',
        baseURL: BASE_URL,
        data: params,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            'Accept': 'application/json'
          }
        }
      }).then((res) => {
        pagedata.processes = res.data.items
      })
    }

    function getEmployees() {
      const params = {
        manager_id: window.btoa(user_details.value.account_id),
        process_id: window.btoa(process.process_id),
        process_type: window.btoa(pagedata.packet_details.type),
      }
      if (process.process_id != '0') {

        axios({
          url: 'api/master/get_employees_by_manager_process',
          method: 'post',
          baseURL: BASE_URL,
          data: params,
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
          config: {
            headers: {
              'Accept': 'application/json'
            }
          }
        }).then((res) => {
          pagedata.employees = res.data.items
          process.employee_id = '0'
        })
        axios({
          url: 'api/master/get_jober_details_by_process',
          method: 'post',
          baseURL: BASE_URL,
          data: params,
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
          config: {
            headers: {
              'Accept': 'application/json'
            }
          }
        }).then((res) => {
          pagedata.parties = res.data.items
          process.party_id = '0'
        })
      } else {
        pagedata.employees = []
        pagedata.parties = []
        process.party_id = '0'
        process.employee_id = '0'
      }
    }

    function getAllManager() {
      axios({
        url: 'api/master/dp_managers',
        method: 'post',
        baseURL: BASE_URL,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            'Accept': 'application/json'
          }
        }
      }).then((res) => {
        pagedata.managers = res.data.items
        process.manager_id = '0'
      })
    }

    function AssignProcess() {
      pagedata.submit = true
      const params = {
        id: window.btoa(process.id),
        number: window.btoa(process.number),
        externals: window.btoa(process.eternals),
        process_id: process.process_id == '0' ? '' : window.btoa(process.process_id),
        employee_id: process.employee_id == '0' ? '' : window.btoa(process.employee_id),
        manager_id: process.manager_id == '0' ? '' : window.btoa(process.manager_id),
        party_id: process.party_id == '0' ? '' : window.btoa(process.party_id),
        transaction_type: window.btoa(process.transaction_type),
        comment: window.btoa(process.comment),
      }
      pagedata.submit = true
      axios({
        url: 'api/process/manager_single_issue',
        method: 'post',
        baseURL: BASE_URL,
        data: params,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            'Accept': 'application/json'
          }
        }
      }).then((res) => {
        pagedata.submit = false
        pagedata.success.success = res.data.success
        console.warn(res)
        setTimeout(() => {
          basicNonStickyNotificationPacketReceive()
        }, 100)
        setTimeout(() => {
          pagedata.success.success = ''
        }, 4000);
        cash('#new-process-modal').modal('hide')
        pagedata.search = pagedata.packet_details.number
        // if(pagedata.check) {
        getPaketDetail()
        // }
        process.employee_id = '0'
        process.manager_id = '0'
        process.process_id = '0'
        process.party_id = '0'
        process.eternals = '0'
        process.transaction_type = '0'
      }).catch((err) => {
        pagedata.submit = false
        let error = ''

        if (err.response.data.error) {
          error = err.response.data.error
          swal("Warning!", error, "warning");
        } else {
          pagedata.errors = err.response.data.errors
          setTimeout(() => {
            pagedata.errors = []
          }, 5000);
        }
      })
    }

    function getDiamondDetail(process_details) {
      cash('#packet-process-receive-modal').modal('show')
      receiveDet.id = process_details.id
      receiveDet.paket_no = pagedata.packet_details.number
      receiveDet.vepari_no = pagedata.packet_details.vepari_no
      receiveDet.issue_weight = pagedata.packet_details.cr_weight
      receiveDet.process_name = process_details.process_name + ' (' + process_details.process_short_code + ' ) '
      receiveDet.color = pagedata.packet_details.color.id
      receiveDet.cut = pagedata.packet_details.cut.id
      receiveDet.purity = pagedata.packet_details.purity.id
      receiveDet.symmetry = pagedata.packet_details.symmetry.id
      receiveDet.polish = pagedata.packet_details.polish.id
      receiveDet.fluerocent = pagedata.packet_details.fluerocent.id
      receiveDet.tension = pagedata.packet_details.tension.id
      receiveDet.shape = pagedata.packet_details.shape.id
      receiveDet.stone = pagedata.packet_details.stone.id
      receiveDet.pktwt = pagedata.packet_details.cr_weight
      receiveDet.type = process_details.packet_type
    }

    function getDiamontData() {
      axios({
        url: 'api/master/get_all_parameters',
        method: 'post',
        baseURL: BASE_URL,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            'Accept': 'application/json'
          }
        }
      }).then((res) => {
        diamond.colors = res.data.color
        diamond.cuts = res.data.cut
        diamond.purities = res.data.purity
        diamond.polish = res.data.polish
        diamond.shapes = res.data.shape
        diamond.symmetry = res.data.symmetry
        diamond.fluerocents = res.data.fluerocent
        diamond.tensions = res.data.tension
        diamond.stones = res.data.stone
        // pagedata.labs = res.data.lab
      })
    }

    function createRateRequest() {
      pagedata.submit = true
      axios({
        url: 'api/request/create_rate_request',
        method: 'post',
        baseURL: BASE_URL,
        data: receiveDet,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
      }).then((res) => {
        pagedata.submit = false
        pagedata.success.success = res.data.success
        pagedata.search = pagedata.packet_details.number
        if (pagedata.check) {
          getPaketDetail()
        }
        cash('#rate-show-confirmation-modal').modal('hide')
        cash('#packet-process-receive-modal').modal('hide')
        cash('#success-modal-preview').modal('show')
        setTimeout(() => {
          pagedata.success.success = ''
          cash('#success-modal-preview').modal('hide')
        }, 3000);
      }).catch(() => {
        pagedata.submit = false
      })
    }

    function createParamRequest(data) {

      swal({
        title: data.message,
        text: data.addition,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm & Accept",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {

          let promise = axios({
            url: "api/request/add_parameter_update_request",
            method: "post",
            data: params,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: {
              headers: {
                Accept: "application/json"
              }
            }
          });
          return promise
            .then(result => result.data)
            .then(data => {
              if (data.status == "error") {
                swal("Error!", data.message, "error");
              } else {
                swal("Success!", data.message, "success");
                pagedata.search = pagedata.packet_details.number
                cash('#packet-process-receive-modal').modal('hide')
                getPaketDetail()
              }
            })
            .catch(_error => {
              return [];
            });
        },
        allowOutsideClick: () => !swal.isLoading()
      }).then(result => {
        if (result.value) {
          //swal("Success!", "User Password Updated.", "success");
        }
      });
      // pagedata.submit = true
      // axios({
      //   url: 'api/request/add_parameter_update_request',
      //   method: 'post',
      //   baseURL: BASE_URL,
      //   data: receiveDet,
      //   headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      // }).then((res) => {
      //   pagedata.submit = false
      //   pagedata.success.success = res.data.success
      //   pagedata.search = pagedata.packet_details.number
      //   // if(pagedata.check) {
      //     getPaketDetail()
      //   // }
      //   cash('#rate-show-confirmation-modal').modal('hide')
      //   cash('#packet-process-receive-modal').modal('hide')
      //   cash('#success-modal-preview').modal('show')
      //   setTimeout(() => {
      //     pagedata.success.success = ''
      //     cash('#success-modal-preview').modal('hide')
      //   }, 3000);
      // }).catch(() => {
      //   pagedata.submit = false
      // })
    }
    const receivePKT = async () => {

      if (receiveDet.receive_weight == '' || receiveDet.receive_weight == null) {
        swal('Warning', 'Receive weight required.', 'warning')
      }

      let issue_weight = receiveDet.issue_weight ? receiveDet.issue_weight : 0
      let receive_weight = receiveDet.receive_weight ? receiveDet.receive_weight : 0
      let assort_weight = receiveDet.assort_weight ? receiveDet.assort_weight : 0
      if (parseFloat(receive_weight) > parseFloat(issue_weight)) {
        receiveDet.receive_weight = ''
        swal('Warning', 'Receive weight grater then issue weight', 'warning')
        return;
      }
      if ((parseFloat(receive_weight) + parseFloat(assort_weight)) > parseFloat(issue_weight)) {
        receiveDet.receive_weight = ''
        swal('Warning', 'Receive weight grater then issue weight', 'warning')
        return;
      }

      pagedata.submit = true

      let promise = axios({
        url: "api/process/receive_single_packet",
        method: "post",
        data: receiveDet,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: {
          headers: {
            Accept: "application/json"
          }
        }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          pagedata.submit = false

          if (data.status == 'success') {

            swal('Success', data.message, 'success')
            cash('#packet-process-receive-modal').modal('hide')
            pagedata.search = pagedata.packet_details.number
            getPaketDetail()

            receiveDet.receive_weight = ''
            receiveDet.assort_weight = ''
            receiveDet.net_assort_weight = ''

          } else {

            // pagedata.submit = false
            // if(err.response.data.error) {
            //   pagedata.errors.error = err.response.data.error
            //   pagedata.errors.message = err.response.data.message
            //   pagedata.errors.data = err.response.data.data
            //   receiveDet.type = err.response.data.type
            //   cash('#rate-show-confirmation-modal').modal('show')
            // } else {

            if (data.addition) {
              createParamRequest(data)
            } else {
              swal('Error', data.message, 'error')
            }
            // }

          }
        })
        .catch(_error => {
          pagedata.submit = false
          swal('Warning', 'Something went wrong.', 'success')
        });
      // pagedata.errors = []
      // axios({
      //   url: 'api/process/receive_single_packet',
      //   method: 'post',
      //   baseURL: BASE_URL,
      //   data: receiveDet,
      //   headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      //   config: { headers: { 'Accept':'application/json' } }
      // }).then((res) => {
      //   console.warn(res)
      //   pagedata.submit = false
      //   pagedata.success.success = res.data.success
      //   setTimeout(() => {
      //     basicNonStickyNotificationPacketReceive()
      //   }, 100);
      //   setTimeout(() => {
      //     pagedata.success.success = ''
      //   }, 4000);
      //   cash('#packet-process-receive-modal').modal('hide')
      //   pagedata.search = pagedata.packet_details.number
      //   // if(pagedata.check) {
      //     getPaketDetail()
      //   // }
      // }).catch((err) => {
      //     if(err.response.data.error) {
      //       pagedata.errors.error = err.response.data.error
      //       pagedata.errors.message = err.response.data.message
      //       pagedata.errors.data = err.response.data.data
      //       receiveDet.type = err.response.data.type
      //       cash('#rate-show-confirmation-modal').modal('show')
      //     } else {
      //       pagedata.errors = err.response.data.errors
      //       setTimeout(() => {
      //         pagedata.errors = []
      //       }, 4000);
      //     }
      //         pagedata.submit = false
      // })
    }

    function cancelProcess() {
      cash('#cancel-confirmation-modal').modal('show')
    }

    function updateParameters(changes) {
      cash('#middle-parameter-confirmation-modal').modal('show')
      pagedata.changes = changes
    }

    function sendRequestMdlParam() {
      pagedata.submit = true
      axios({
        url: 'api/request/add_parameter_edit_request',
        method: 'post',
        baseURL: BASE_URL,
        data: pagedata.changes,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
      }).then((res) => {
        pagedata.submit = false
        pagedata.success.success = res.data.success
        cash('#middle-parameter-confirmation-modal').modal('hide')
        setTimeout(() => {
          cash('#success-modal-preview').modal('show')
        }, 100);
        pagedata.search = pagedata.packet_details.number
        // if(pagedata.check) {
        getPaketDetail()
        // }
        setTimeout(() => {
          cash('#success-modal-preview').modal('hide')
          pagedata.success.success = ''
        }, 4000);
      }).catch((err) => {
        pagedata.submit = false
        pagedata.error = err.response.data.error
        setTimeout(() => {
          basicNonStickyNotificationPacketError()
        }, 100);
        setTimeout(() => {
          pagedata.error = ''
        }, 4000);
      })
    }

    function cancelTransaction() {
      pagedata.submit = true
      const params = {
        id: window.btoa(pagedata.packet_details.id)
      }
      axios({
        url: 'api/process/cancel_process',
        method: 'post',
        baseURL: BASE_URL,
        data: params,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            'Accept': 'application/json'
          }
        }
      }).then((res) => {
        pagedata.submit = false
        pagedata.success.success = res.data.success
        cash('#cancel-confirmation-modal').modal('hide')
        setTimeout(() => {
          cash('#success-modal-preview').modal('show')
        }, 100);
        pagedata.search = pagedata.packet_details.number
        // if(pagedata.check) {
        getPaketDetail()
        // }
        setTimeout(() => {
          cash('#success-modal-preview').modal('hide')
          pagedata.success.success = ''
        }, 4000);
      }).catch((err) => {
        pagedata.submit = false
        pagedata.error = err.response.data.error
        setTimeout(() => {
          basicNonStickyNotificationPacketError()
        }, 100);
        setTimeout(() => {
          pagedata.error = ''
        }, 4000);
      })
    }
    const basicNonStickyNotificationPacketReceive = () => {
      Toastify({
        node: cash('#basic-non-sticky-notification-packet-receive')
          .clone()
          .removeClass('hidden')[0],
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    // watch(() => pagedata.search, (currentValue) => {
    // })
    function getPacketDetailsScan() {
      if (!pagedata.check && pagedata.search) {
        getPaketDetail();
      }
    }

    function validateManagerId() {
      if (user_details.value.account_id == process.manager_id) {
        process.manager_id = '0'
        swal("Warning!", "Self Transfre is Not Allowed !", "warning");
      }
    }

    const validateReceiveWeight = () => {

      let issue_weight = receiveDet.issue_weight ? receiveDet.issue_weight : 0
      let receive_weight = receiveDet.receive_weight ? receiveDet.receive_weight : 0
      let assort_weight = receiveDet.assort_weight ? receiveDet.assort_weight : 0
      let net_assort_weight = receiveDet.net_assort_weight ? receiveDet.net_assort_weight : 0
      if (parseFloat(receive_weight) > parseFloat(issue_weight)) {
        receiveDet.receive_weight = ''
        swal('Warning', 'Receive weight grater then issue weight', 'warning')
        return;
      }
      if ((parseFloat(receive_weight) + parseFloat(assort_weight) + parseFloat(net_assort_weight)) > parseFloat(issue_weight)) {
        receiveDet.receive_weight = ''
        swal('Warning', 'Receive weight grater then issue weight', 'warning')
        return;
      }
    }
    const validateAssortWeight = () => {
      let issue_weight = receiveDet.issue_weight ? receiveDet.issue_weight : 0
      let receive_weight = receiveDet.receive_weight ? receiveDet.receive_weight : 0
      let assort_weight = receiveDet.assort_weight ? receiveDet.assort_weight : 0
      let net_assort_weight = receiveDet.net_assort_weight ? receiveDet.net_assort_weight : 0
      if (parseFloat(assort_weight) > parseFloat(issue_weight)) {
        receiveDet.assort_weight = ''
        swal('Warning', 'Rejection weight grater then issue weight', 'warning')
        return;
      }
      if ((parseFloat(receive_weight) + parseFloat(assort_weight) + parseFloat(net_assort_weight)) > parseFloat(issue_weight)) {
        receiveDet.assort_weight = ''
        swal('Warning', 'Rejection weight grater then issue weight', 'warning')
        return;
      }
    }
    const validateNetAssortWeight = () => {
      let issue_weight = receiveDet.issue_weight ? receiveDet.issue_weight : 0
      let receive_weight = receiveDet.receive_weight ? receiveDet.receive_weight : 0
      let assort_weight = receiveDet.assort_weight ? receiveDet.assort_weight : 0
      let net_assort_weight = receiveDet.net_assort_weight ? receiveDet.net_assort_weight : 0
      if (parseFloat(net_assort_weight) > parseFloat(issue_weight)) {
        receiveDet.net_assort_weight = ''
        swal('Warning', 'Net Rejection weight grater then issue weight', 'warning')
        return;
      }
      if ((parseFloat(receive_weight) + parseFloat(assort_weight) + parseFloat(net_assort_weight)) > parseFloat(issue_weight)) {
        receiveDet.net_assort_weight = ''
        swal('Warning', 'Net Rejection weight grater then issue weight', 'warning')
        return;
      }
    }
    const basicNonStickyNotificationPacketError = () => {
      Toastify({
        node: cash('#basic-non-sticky-notification-packet-error')
          .clone()
          .removeClass('hidden')[0],
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    return {
      focusMe,
      pagedata,
      user_details,
      receiveDet,
      process,
      diamond,
      basicNonStickyNotificationPacketError,
      basicNonStickyNotificationPacketReceive,
      getPaketDetail,
      getPacketDetailPkt,
      printData,
      getEmployees,
      receivePKT,
      getDiamondDetail,
      createRateRequest,
      createParamRequest,
      cancelProcess,
      getProcessesCheck,
      AssignProcess,
      updateParameters,
      sendRequestMdlParam,
      cancelTransaction,
      validateManagerId,
      getPacketDetailsScan,
      validateReceiveWeight,
      validateAssortWeight,
      validateNetAssortWeight
    }
  }
})
</script>
