<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Packet Details</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
      </div>
    </div>
    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
          <div class="report-box zoom-in">
            <div class="box p-2">
              <div class="flex items-center justify-between">
                <!-- <ActivityIcon class="report-box__icon text-theme-10" /> -->
                <div class="text-base text-gray-600"> Packets</div>
                <div class="text-xl font-medium leading-8">{{ pagedata.total.total_packets }}</div>
              </div>
            </div>
          </div>
      </div>
      <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
        <div class="report-box zoom-in">
          <div class="box p-2">
            <div class="flex items-center justify-between">
              <!-- <CheckCircleIcon class="report-box__icon text-theme-11" /> -->
              <div class="text-base text-gray-600"> Weight</div>
              <div class="text-xl font-medium leading-8">{{ pagedata.total.total_weight ? $h.myFloat(pagedata.total.total_weight) + ' ct' : '0.00 ct' }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="intro-y box p-3 mt-5">
      <div class="grid grid-cols-12 gap-x-2">
        <div class="col-span-6 sm:col-span-3 md:col-span-2">
          <div class="mt-3">
            <label for="update-profile-form-6" class="form-label"
              >Kapan No</label
            >
            <TomSelect
              v-model="filter.lot_no"
              :options="{
                placeholder: 'Select Kapan No',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Kapan '
                  }
                }
              }"
              class="w-full"
              @change="getPacketDetails"
            >
            <option value="0">Select Kapan</option>
            <option v-for="lot in pagedata.lots" :key="lot.id" :value="lot.id">{{lot.lot_no}} ({{ lot.vepari_no }})</option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 sm:col-span-3 md:col-span-2">
          <div class="mt-3">
            <label for="update-profile-form-6" class="form-label"
              >Packet Type</label
            >
            <TomSelect
              v-model="filter.type"
              :options="{
                placeholder: 'Select Lot No',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Lot '
                  }
                }
              }"
              class="w-full"
              @change="getPacketDetails"
            >
            <option value="0">SELECT TYPE</option>
            <option value="makeable">MAKEABLE STOCK</option>
            <option value="rough">ROUGH STOCK</option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 sm:col-span-3 md:col-span-2" v-if="[1,2,8].includes(user_details.user_role)">
          <div class="mt-3">
            <label for="update-profile-form-6" class="form-label"
              >Packet Status</label
            >
            <TomSelect
              v-model="filter.status"
              :options="{
                placeholder: 'Select Status',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Lot '
                  }
                }
              }"
              class="w-full"
              @change="getPacketDetails"
            >
            <option value="0">Select Status</option>
            <option value="1">Active</option>
            <option value="2">In Process</option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 sm:col-span-3 md:col-span-2">
          <div class="mt-3">
            <label for="update-profile-form-6" class="form-label"
              >Packet Number</label
            >
            <input
              id="tabulator-html-filter-value"
              v-model="filter.packet_number"
              type="text"
              class="form-control  w-full mt-2 sm:mt-0"
              placeholder="Packet Number"
            />
          </div>
        </div>

        <div class="col-span-6 md:col-span-1">
          <div class="mt-10">
          <button
            id="tabulator-html-filter-reset"
            type="button"
            class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-1"
            @click="onResetFilter"
          >
            Reset
          </button>
          </div>
        </div>
        <div class="col-span-4 md:col-span-1">

          <div class="mt-10">
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full sm:w-20"
              @click="getPacketDetails"
            >
            Generate
            </button>

          </div>
          </div>
      </div>
      <div class="shadow-md mt-3">
        <table class="w-full mt-2 border">
          <thead>
            <tr class="bg-theme-1 text-white">
              <th class="p-2 whitespace-nowrap text-left">Packet</th>
              <th class="p-2 whitespace-nowrap text-center">Stock No</th>
              <th class="p-2 whitespace-nowrap text-center">Cr Weight</th>
              <th class="p-2 whitespace-nowrap text-center" v-if="![4,5,6].includes(user_details.user_role)">Polish Weight</th>
              <th class="p-2 whitespace-nowrap text-center">Type</th>
              <th class="p-2 border-b text-center" v-if="![4,5,6].includes(user_details.user_role)">
                <MultipleSelect :options="pagedata.parameters.shape" :name="'shape'" @onChecked="onShapeCheck"></MultipleSelect>
              </th>
              <th class="p-2  border-b text-center" v-if="![4,5,6].includes(user_details.user_role)">
                <MultipleSelect :options="pagedata.parameters.color" :name="'color'" @onChecked="onColorCheck"></MultipleSelect>
              </th>
              <th class="p-2  border-b text-center" v-if="![4,5,6].includes(user_details.user_role)">
                <MultipleSelect :options="pagedata.parameters.purity" :name="'purity'" @onChecked="onPurityCheck"></MultipleSelect>
              </th>
              <th class="p-2  border-b text-center" v-if="![4,5,6].includes(user_details.user_role)">
                <MultipleSelect :options="pagedata.parameters.cut" :name="'cut'" @onChecked="onCutCheck"></MultipleSelect>
              </th>
              <th class="p-2  border-b text-center" v-if="![4,5,6].includes(user_details.user_role)">
                <MultipleSelect :options="pagedata.parameters.polish" :name="'polish'" @onChecked="onPolishCheck"></MultipleSelect>
              </th>
              <th class="p-2  border-b text-center" v-if="![4,5,6].includes(user_details.user_role)">
                <MultipleSelect :options="pagedata.parameters.symmetry" :name="'symmetry'" @onChecked="onSymmetryCheck"></MultipleSelect>
              </th>
              <th class="p-2  border-b text-center" v-if="![4,5,6].includes(user_details.user_role)">
                <MultipleSelect :options="pagedata.parameters.fluerocent" :name="'fluerocent'" @onChecked="onFluerocentCheck"></MultipleSelect>
              </th>
              <th class="p-2  border-b text-center" v-if="![4,5,6].includes(user_details.user_role)">
                <MultipleSelect :options="pagedata.parameters.tension" :name="'tension'" @onChecked="onTensionCheck"></MultipleSelect>
              </th>
              <th class="p-2  border-b text-center">
                <MultipleSelect :options="pagedata.parameters.stone" :name="'stone'" @onChecked="onStoneCheck"></MultipleSelect>
              </th>
            </tr>
          </thead>
          <tbody v-if="pagedata.packets.length > 0">
            <tr v-for="(packet, index) in pagedata.packets" :key="index" class="border hover:bg-gray-200 p-0 cursor-pointer">
              <td class="py-2 border-b w-40">
                <span v-if="user_details.user_role == 5 || user_details.user_role == 6">
                  <span v-if="packet.transaction_packet_status == 1 && packet.transaction_status == 1">
                    <PackageIcon class="w-6 h-6 font-bold text-theme-1 mr-1" />
                  </span>
                  <span v-else-if="packet.transaction_packet_status == 1 && packet.transaction_status == 2">
                    <PackageIcon class="w-6 h-6 font-bold text-theme-9 mr-1" />
                  </span>
                </span>
                <span v-else>
                  <span v-if="(packet.transaction_packet_status == 1 || (packet.transaction_packet_status == 1 && packet.transaction_status == 1)) && (packet.status == 1 || packet.status == 5)">
                    <span v-if="packet.transaction_packet_status == 1 && packet.transaction_status == 1">
                      <PackageIcon class="w-6 h-6 font-bold text-theme-1 mr-1" />
                    </span>
                    <span v-else-if="packet.transaction_packet_status == 1 && packet.transaction_status == 2">
                      <PackageIcon class="w-6 h-6 font-bold text-theme-9 mr-1" />
                    </span>
                  </span>
                  <span v-else>
                    <PackageIcon class="w-6 h-6 font-bold text-theme-6 mr-1" />
                  </span>
                </span>
                <span :class="{'text-theme-6' : packet.urgent}">{{ packet.number }}</span>
              </td>
              <!-- <td class="py-2 border-b text-center">{{ packet.weight }}</td> -->
              <td class="py-2 border-b">{{ packet.vepari_no }}</td>
              <td class="py-2 border-b text-center">{{ packet.cr_weight }}</td>
              <td class="py-2 border-b text-center" v-if="![4,5,6].includes(user_details.user_role)">{{ packet.cr_polish_weight ? packet.cr_polish_weight : '-' }}</td>
              <td class="py-2 border-b text-center">{{ packet.type }}</td>
              <td class="py-2 border-b text-center" v-if="![4,5,6].includes(user_details.user_role)">{{ packet.shape_name ? packet.shape_name : '-' }}</td>
              <td class="py-2 border-b text-center" v-if="![4,5,6].includes(user_details.user_role)">{{ packet.color_name ? packet.color_name : '-' }}</td>
              <td class="py-2 border-b text-center" v-if="![4,5,6].includes(user_details.user_role)">{{ packet.purity_name ? packet.purity_name : '-' }}</td>
              <td class="py-2 border-b text-center" v-if="![4,5,6].includes(user_details.user_role)">{{ packet.cut_name ? packet.cut_name : '-' }}</td>
              <td class="py-2 border-b text-center" v-if="![4,5,6].includes(user_details.user_role)">{{ packet.polish_name ? packet.polish_name : '-' }}</td>
              <td class="py-2 border-b text-center" v-if="![4,5,6].includes(user_details.user_role)">{{ packet.symmetry_name ? packet.symmetry_name : '-' }}</td>
              <td class="py-2 border-b text-center" v-if="![4,5,6].includes(user_details.user_role)">{{ packet.fluerocent_name ? packet.fluerocent_name : '-' }}</td>
              <td class="py-2 border-b text-center" v-if="![4,5,6].includes(user_details.user_role)">{{ packet.tension_name ? packet.tension_name : '-' }}</td>
              <td class="py-2 border-b text-center">{{ packet.stone_name }}</td>
            </tr>
          </tbody>
        </table>
        <!-- BEGIN: Pagination -->
        <!-- END: Pagination -->
      </div>
      <div v-if="pagedata.packets.length > 0"
          class="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center mt-3 mb-7"
        >
          <ul class="pagination">
            <li>
              <button class="pagination__link" :disabled="pagedata.current_page == 1" @click="getAvailablePkt(pagedata.first_page_url)">
                <ChevronsLeftIcon class="w-4 h-4" />
              </button>
            </li>
            <li>
              <button class="pagination__link" :disabled="pagedata.prev_page_url == null" @click="getAvailablePkt(pagedata.prev_page_url)">
                <ChevronLeftIcon class="w-4 h-4" />
              </button>
            </li>
            <li v-for="(pagelink,index) in pagelinks" :key="index">
              <button class="pagination__link" :class="{'pagination__link--active' : pagelink.active == true}" v-if="pagelinks[0] != pagelink && pagelinks[pagelinks.length - 1] != pagelink" @click='getAvailablePkt(pagelink.url)'>{{ pagelink.label }}</button>
            </li>
            <li>
              <button class="pagination__link" :disabled="pagedata.next_page_url == null" @click="getAvailablePkt(pagedata.next_page_url)">
                <ChevronRightIcon class="w-4 h-4" />
              </button>
            </li>
            <li>
              <button class="pagination__link" :disabled="pagedata.current_page == pagedata.last_page" @click="getAvailablePkt(pagedata.last_page_url)">
                <ChevronsRightIcon class="w-4 h-4" />
              </button>
            </li>
          </ul>
          <select class="w-20 form-select box mt-3 sm:mt-0" v-model="filter.size" @change="getAvailPkt">
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
          </select>
        </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config';
import axios from 'axios';
import MultipleSelect from '@/components/MultipleSelect/Main.vue'
import { computed, defineComponent, onMounted, reactive, toRef, watch } from 'vue';
import store from '@/store';

export default defineComponent({
  components:{
    MultipleSelect
  },
  setup() {
    const user_details = computed(() => store.state.user.currentUser)
    const pagedata = reactive({
      packets: [],
      total: [],
      lots: [],
      filters: true,
      pagelinks: [],
      prev_page_url: null,
      next_page_url: null,
      first_page_url: null,
      last_page_url: null,
      current_page: 1,
      last_page: '',
      parameters: {
        color: [],
        cut: [],
        purity: [],
        polish: [],
        shape: [],
        symmetry: [],
        fluerocent: [],
        tension: [],
        stone: []
      }
    })
    const pagelinks = toRef(pagedata, 'pagelinks')
    const filter = reactive({
      size : 20,
      packet_number : '',
      lot_no : '',
      status : '0',
      type : '0',
      color: [],
      cut: [],
      purity: [],
      polish: [],
      shape: [],
      symmetry: [],
      fluerocent: [],
      tension: [],
      stone: [],
    })
    onMounted(() => {
      getAvailPkt()
      total_packets()
      getLotDetails()
      getDiamontData()
    })
    function onColorCheck(val) {
      filter.color = val;
      getPacketDetails()
    }
    function onPurityCheck(val) {
      filter.purity = val;
      getPacketDetails()
    }
    function onCutCheck(val) {
      filter.cut = val;
      getPacketDetails()
    }
    function onPolishCheck(val) {
      filter.polish = val;
      getPacketDetails()
    }
    function onShapeCheck(val) {
      filter.shape = val;
      getPacketDetails()
    }
    function onSymmetryCheck(val) {
      filter.symmetry = val;
      getPacketDetails()
    }
    function onStoneCheck(val) {
      filter.stone = val;
      getPacketDetails()
    }
    function onFluerocentCheck(val) {
      filter.fluerocent = val;
      getPacketDetails()
    }
    function onTensionCheck(val) {
      filter.tension = val;
      getPacketDetails()
    }
    // function onCheck(val) {
    //   pagedata.selected = val;
    // }
    function getAvailablePkt(url) {
      axios({
        url: url,
        method: 'post',
        data: filter,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.packets = res.data.data
        pagedata.pagelinks = res.data.links
        pagedata.prev_page_url = res.data.prev_page_url
        pagedata.next_page_url = res.data.next_page_url
        pagedata.first_page_url = res.data.first_page_url
        pagedata.last_page_url = res.data.last_page_url
        pagedata.current_page = res.data.current_page
        pagedata.last_page = res.data.last_page
    })
    }
    function getAvailPkt() {
       axios({
        url: `api/purchase/get_owner_packet_details`,
        method: 'post',
        baseURL: BASE_URL,
        data: filter,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.packets = res.data.data
        pagedata.pagelinks = res.data.links
        pagedata.prev_page_url = res.data.prev_page_url
        pagedata.next_page_url = res.data.next_page_url
        pagedata.first_page_url = res.data.first_page_url
        pagedata.last_page_url = res.data.last_page_url
        pagedata.current_page = res.data.current_page
        pagedata.last_page = res.data.last_page
      })
    }
    function getDiamontData() {
      axios({
        url: 'api/master/get_all_parameters',
        method: 'post',
        baseURL: BASE_URL,
        data: filter,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.parameters.color = res.data.color
        pagedata.parameters.cut = res.data.cut
        pagedata.parameters.purity = res.data.purity
        pagedata.parameters.symmetry = res.data.symmetry
        pagedata.parameters.polish = res.data.polish
        pagedata.parameters.fluerocent = res.data.fluerocent
        pagedata.parameters.tension = res.data.tension
        pagedata.parameters.shape = res.data.shape
        pagedata.parameters.stone = res.data.stone
      })
    }
    function total_packets() {
      axios({
        url: "api/purchase/get_owner_packet_details_total",
        method: 'post',
        baseURL: BASE_URL,
        data: filter,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {

          pagedata.total = res.data.total;
        })
    }
    function getLotDetails() {
      axios({
        url: 'api/master/dp_lot_details',
        method: 'post',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.lots = res.data.items
      })
    }
    // watch(() => filter.color, (current) => {
    //     getAvailPkt()
    // })
    // watch(() => filter.cut, (current) => {
    //     getAvailPkt()
    // })
    // watch(() => filter.purity, (current) => {
    //     getAvailPkt()
    // })
    // watch(() => filter.polish, (current) => {
    //     getAvailPkt()
    // })
    // watch(() => filter.shape, (current) => {
    //     getAvailPkt()
    // })
    // watch(() => filter.symmetry, (current) => {
    //     getAvailPkt()
    // })
    // watch(() => filter.fluerocent, (current) => {
    //     getAvailPkt()
    // })
    // watch(() => filter.tension, (current) => {
    //     getAvailPkt()
    // })
    // watch(() => filter.stone, (current) => {
    //     getAvailPkt()
    // })
    // On reset filter
    const onResetFilter = () => {
      filter.lot_no = '0'
      filter.packet_number = ''
      filter.color = []
      filter.cut = []
      filter.purity = []
      filter.polish = []
      filter.shape = []
      filter.symmetry = []
      filter.fluerocent = []
      filter.tension = []
      filter.stone = []
      filter.status = '0'
      getPacketDetails()
    }
    function getPacketDetails() {
      getAvailPkt()
      total_packets()
    }
    return {
      getAvailablePkt,
      getAvailPkt,
      pagedata,
      filter,
      user_details,
      pagelinks,
      onColorCheck,
      onPurityCheck,
      onCutCheck,
      onPolishCheck,
      onShapeCheck,
      onSymmetryCheck,
      onFluerocentCheck,
      onStoneCheck,
      onTensionCheck,
      onResetFilter,
      getPacketDetails
    }
  }
})
</script>
<style scoped>
input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .custom-number-input input:focus {
    outline: none !important;
  }

  .custom-number-input button:focus {
    outline: none !important;
  }
  .dropdown {
  position: relative;
  cursor: pointer;
}

</style>
